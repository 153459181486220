/* MyComponent.css */

.custom-dropdown {
  width: 145px;
  height: 30px;
  display: inline-block;
  margin-right: 50px;
  z-index: 1;
}

.input-container {
  border: 1px solid #ccc;
  padding: 5px;
  cursor: pointer;
}

.options-container {
  top: 100%;
  left: 0;
  width: 100%;
  background: #fff;
  border: 1px solid #ccc;
  max-height: 200px; /* Set a maximum height for the dropdown options */
  overflow-y: auto;
}

.option {
  padding: 8px;
  cursor: pointer;
}

.option:hover {
  background-color: #f2f2f2;
}
  